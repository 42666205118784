import "./help.css";

export const Help = ({ lenderName, lenderEmail, lenderPhone }) => {
  return (
    <div className="help-container">
      <div className="logo-help">
        <img alt="success" src="/help-circle.svg" />
      </div>
      <div className="content">
        <div className="title">Having trouble?</div>
        <div className="text">
          If you have any difficulty or issues finding your bank or logging in,
          please contact us.
        </div>
        <div className="email"><a href={`mailto:${lenderEmail}`}>{lenderEmail}</a></div>
        <div className="phone"><a href={`tel:${lenderPhone}`}>{lenderPhone}</a></div>
      </div>
    </div>
  );
};
