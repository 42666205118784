// import logo from "./logo.svg";
import "./App.css";
import { useEffect, useState, useCallback  } from "react";
import SidebarContainer from "./containers/sidebar";
import { logEvent } from "./log-event";
import { useDebounce } from "./debounce";

const CONNECTED_EVENT = "mx/connect/memberConnected";
const CONNECTED_MSG = "banklogin/connected";
const FAILED_MSG = "banklogin/failed";
const MAX_API_COUNT = 3;

const BASE_URL = process.env.REACT_APP_IBZ_API_BASE_URL;
const API_KEY = process.env.REACT_APP_IBZ_API_KEY;

const ELoan_Dict = {
  name: "eLoan Warehouse",
  email: "Customers@eLoanWarehouse.com",
  phone: "+1 855-650-6641",
};

const Lokyata_Dict = {
  name: "Lokyata",
  email: "help@lokyata.com",
  phone: "+1 202-355-9480",
};

const clearlineloans = {
  name: "Clearline Loans",
  email: "cs@clearlineloans.com",
  phone: "1-888-542-1015",
};
const greenarrowloans = {
  name: "GreenArrow Loans",
  email: "cs@greenarrowloans.com",
  phone: "1-877-596-1340",
};

const greenlineloans = {
  name: "Greenline Loans",
  email: "cs@greenlineloans.com",
  phone: "1-877-596-1337",
};

const Crediterium_Dict = {
  name: "Crediterium",
  email: "support@crediterium.com",
  phone: "(844) 427-3876",
};

const Eagleadvance_Dict = {
  name: "EagleAdvance",
  email: "customersupport@eagleadvance.com",
  phone: "888-920-1530",
};

const life_asset_Dict = {
  name: "LifeAsset",
  email: "info@lifeasset.org",
  phone: "202-709-0652",
};

const xc_Dict = {
  name: "LTX CU",
  email: "info@xpresscredit.net",
  phone: "-",
};

const LENDER_DICTIONARY = {
  lcofs: ELoan_Dict,
  ALPHA1_TX: Crediterium_Dict,
  alpha1_tx: Crediterium_Dict,
  lokyata: Lokyata_Dict,
  oxf: Lokyata_Dict,
  im: Lokyata_Dict,
  clearline_loans: clearlineloans,
  greenarrow_loans: greenarrowloans,
  greenline_loans: greenlineloans,
  eagle_adv_1: Eagleadvance_Dict,
  life_asset: life_asset_Dict,
  xpress_credit: xc_Dict
};

function getReportId(url) {
  try {
    let id = url.split("/");
    if (id.length > 4) {
      return null;
    }
    return id[3];
  } catch (error) {}
  return null;
}

function generateEvent(message) {
  window.parent.postMessage(message, "*");
}

function getMessage(message) {
  return {
    lokyata: true,
    type: message,
  };
}


// createReport should only be called once.
// We get multiple POST messages from MX.
// Use this variable to ensure we do not create duplicate reports.
var isCreateReportCalled = false;
function App() {
  const [connectUrl, setConnectUrl] = useState(null);
  const [isReport, setIsReport] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isDeactivated, setIsDeactivated] = useState(false);
  const [reportId, setReportId] = useState("");
  const [isError, setIsError] = useState(false);
  const [lenderInfo, setLenderInfo] = useState(null);
  const [query, setQuery] = useState('')
  const debouncedMessage = useDebounce(query, 300);



  function createReport(data) {
    const url = BASE_URL + "/create_report";
    const requestOptions = {
      method: "POST",
      headers: {
        "x-api-key": API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        report_id: data.report_id,
        user_guid: data.user_guid,
        member_guid: data.member_guid,
      }),
    };
    fetch(url, requestOptions);
  }

  useEffect(() => {
    function handleEvent (event) {
      const reportId = getReportId(window.location.href);
      if (event.data.mx) {
        console.log("calling log event for: "+reportId)
        if (event.data.type !== 'mx/ping') {
          if (event.data.type === 'mx/connect/institutionSearch'){
            setQuery(event.data.metadata.query)
            if (debouncedMessage || query.length < 0) {
              logEvent(event.data, reportId)
              console.log("query logged: "+query)
            }else {
              console.log("query skipped: "+query)
            };
          } else {
          logEvent(event.data, reportId);
          }
        }
        if (event.data.type === CONNECTED_EVENT) {
          var data = { ...event.data.metadata, report_id: reportId };
          if (reportId === "" || isCreateReportCalled) return;
          isCreateReportCalled = true;
          createReport(data);
          generateEvent(getMessage(CONNECTED_MSG));
          setConnectUrl(null);
          setIsCompleted(true);
        }
      }
    }

    // Add event listener
    window.addEventListener('message', handleEvent);

    // Clean up event listener
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [debouncedMessage, query]);

  async function getClientId(reportId) {
    try {
      let lenderRes = await fetch(BASE_URL + `/report/${reportId}/client_id`, {
        method: "GET",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
        },
      });

      lenderRes = await lenderRes.json();
      const client_id = lenderRes.client_id;
      const lenderDict = LENDER_DICTIONARY[client_id] ?? Lokyata_Dict;
      setLenderInfo(lenderDict);
    } catch (error) {
      setLenderInfo(Lokyata_Dict);
    }
  }

  useEffect(() => {
    const reportId = getReportId(window.location.href);
    if (!reportId) return;
    console.log(reportId)
    setReportId(reportId);
    setIsReport(true);
    var apiCallCount = 0;


    function getConnectURL(reportId) {
      const requestOptions = {
        method: "POST",
        headers: {
          "x-api-key": API_KEY,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          report_id: reportId,
        }),
      };

      fetch(`${BASE_URL}/create_connect_url`, requestOptions)
        .then((res) => res.json())
        .then((res) => {
          if (res.error && res.error.message === "Report already completed") {
            setIsCompleted(true);
          } else if (
            res.error &&
            res.error.message === "Report is deactivated"
          ) {
            setIsDeactivated(true);
          } else if (res.connect_url) {
            setConnectUrl(res.connect_url);
          } else {
            if (apiCallCount < MAX_API_COUNT) {
              apiCallCount += 1;
              getConnectURL(reportId);
              return;
            }

            setIsError(true);
            generateEvent(getMessage(FAILED_MSG));
          }
        })
        .catch(() => {
          if (apiCallCount < MAX_API_COUNT) {
            apiCallCount += 1;
            getConnectURL(reportId);
            return;
          }

          setIsError(true);
          generateEvent(getMessage(FAILED_MSG));
        });
    }

    getClientId(reportId);
    getConnectURL(reportId);
  }, []);

  if (!isReport) {
    return (
      <div className="page-loader">
        <div className="spinner"></div>
        <div className="txt">Loading</div>
      </div>
    );
  }

  return (
    <div className="App">
      {lenderInfo && (
        <SidebarContainer
          lenderEmail={lenderInfo.email}
          lenderPhone={lenderInfo.phone}
          lenderName={lenderInfo.name}
        />
      )}

      <div className="layour-wrapper">
        {!connectUrl && !isCompleted && !isError && !isDeactivated && (
          <div className="page-loader loader-initial">
            <div className="spinner"></div>
            <div className="txt">Loading</div>
          </div>
        )}

        {!connectUrl && !isDeactivated && !isError && isCompleted && (
          <div className="success">
            <div className="wrapper">
              <img alt="success" src="/check.svg" />
              <div className="text">
                Thank you for providing your bank statements to expedite your loan process. <br/>
                Our team will contact you shortly. If you have any questions, please feel free to call us. <br/>
                You may now close this browser tab or window.<br/>
              </div>
            </div>
          </div>
        )}

        {!connectUrl && !isCompleted && !isDeactivated && isError && (
          <div className="success">
            <div className="wrapper">
              <img alt="warning" src="/warning.png" />
              <div className="text">Something went wrong.</div>
            </div>
          </div>
        )}

        {!connectUrl &&
          !isCompleted &&
          !isError &&
          isDeactivated &&
          lenderInfo && (
            <div className="success">
              <div className="wrapper">
                <img alt="error" src="/remove.png" />
                <div className="text">
                  This report <strong>{`${reportId} `}</strong> was
                  <span> deactivated</span>. Please contact {lenderInfo.email}{" "}
                  or {lenderInfo.phone} for assistance to receive an updated
                  report link.
                </div>
              </div>
            </div>
          )}

        {connectUrl && !isCompleted && !isDeactivated && (
          <iframe title="ibz bank" src={connectUrl}></iframe>
        )}
      </div>
    </div>
  );
}

export default App;
