import { useState } from "react";
import Header from "../../components/header";
import Sidebar from "../../components/sidebar";
import "./sidebar.css";

export const SidebarContainer = ({ lenderName, lenderEmail, lenderPhone }) => {
  const [openSidebar, setOpenSidebar] = useState(false);

  return (
    <>
      <div className="sidebar-desktop">
        <Sidebar
          lenderName={lenderName}
          lenderEmail={lenderEmail}
          lenderPhone={lenderPhone}
          onClose={() => {}}
        />
      </div>

      <Header lenderName={lenderName} onOpen={() => setOpenSidebar(true)} />

      {openSidebar && (
        <Sidebar
          lenderEmail={lenderEmail}
          lenderPhone={lenderPhone}
          lenderName={lenderName}
          onClose={() => setOpenSidebar(false)}
        />
      )}
    </>
  );
};
