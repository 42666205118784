const BASE_URL = process.env.REACT_APP_IBZ_API_BASE_URL;
const API_KEY = process.env.REACT_APP_IBZ_API_KEY;

export const logEvent = async (event, reportId) => {
  const URL = BASE_URL + `/report/${reportId}/log_mx_event`;
  const requestOptions = {
    method: "POST",
    headers: {
      "x-api-key": API_KEY,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(event),
  };

  await fetch(URL, requestOptions);
};
