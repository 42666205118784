import Help from "./help";
import Privacy from "./privacy";
import "./sidebar.css";
import Step from "./step";

export const Sidebar = ({ onClose, lenderName, lenderEmail, lenderPhone }) => {
  return (
    <div className="sidebar-container">
      {lenderName === "Lokyata" && (
        <div className="logo">
          <img alt="success" src="/logo.png" />
        </div>
      )}
      {lenderName === "Clearline Loans" && (
        <div className="logo-clearline">
          <img alt="success" src="/logo_clearline.jpg" />
        </div>
      )}
      {lenderName === "GreenArrow Loans" && (
        <div className="logo-clearline">
          <img alt="success" src="/logo_greenarrow.jpg" />
        </div>
      )}
      {lenderName === "Greenline Loans" && (
        <div className="logo-clearline">
          <img alt="success" src="/logo_greenline.png" />
        </div>
      )}
      {lenderName === "eLoan Warehouse" && (
        <div className="logo-eloan">
          <img alt="success" src="/logo_eloan.png" />
        </div>
      )}

      {lenderName === "Crediterium" && (
        <div className="logo-crediterium">
          <img alt="success" src="/logo_crediterium.png" />
        </div>
      )}
      {lenderName === "EagleAdvance" && (
        <div className="logo-clearline">
          <img alt="success" src="/logo_eagleadvance.png" />
        </div>
      )}
      {lenderName === "LifeAsset" && (
        <div className="logo-clearline">
          <img alt="success" src="/lifeasset.png" />
        </div>
      )}
      {lenderName === "LTX CU" && (
        <div className="logo-clearline">
          <img alt="success" src="/xc.png" />
        </div>
      )}

      <div className="icon-x" onClick={() => onClose()}>
        <img alt="success" src="/x.svg" />
      </div>

      <div className="language-list">
        <div className="item">English </div>
      </div>

      <div className="sidebar-content">
        <div className="title">Welcome to {lenderName}.</div>
        <div className="text">
          Thank you for your interest in a loan from {lenderName}. The next step
          in the loan approval process is to electronically share your bank
          statements to allow us to verify your income, employment and ability
          to afford a loan payment. The system allows you to securely give
          permission to the bank to share your bank statements with us.
        </div>
      </div>

      <div className="step-list">
        <Step
          index={1}
          color="#f6f2ff"
          description="Search and select your bank"
        />
        <Step
          index={2}
          color="#f4fbff"
          description="Enter your internet banking login information"
        />
        <Step
          index={3}
          color="#e6f8f3"
          description="Click the Connect button"
        />
      </div>
      <Help
        lenderName={lenderName}
        lenderEmail={lenderEmail}
        lenderPhone={lenderPhone}
      />

      <Privacy lenderName={lenderName} />
    </div>
  );
};
