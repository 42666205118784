import "./header.css";

export const Header = ({ onOpen, lenderName }) => {
  return (
    <div className="sidebar-header-mobile">
      {lenderName === "Lokyata" && (
        <div className="logo">
          <img alt="logo" src="/logo.png" />
        </div>
      )}

      {lenderName === "eLoan Warehouse" && (
        <div className="logo-eloan">
          <img alt="logo" src="/logo_eloan.png" />
        </div>
      )}

      {lenderName === "Crediterium" && (
        <div className="logo-crediterium">
          <img alt="success" src="/logo_crediterium.png" />
          <div>
            To share your bank statements and expedite the loan process, please
            Connect your Account.
          </div>
        </div>
      )}

      <div onClick={() => onOpen()} className="help-section">
        <img alt="success" src="/help-circle-purple.svg" />
        <span>Help</span>
      </div>
    </div>
  );
};
