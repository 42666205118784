import "./step.css";

export const Step = ({ index, color, description }) => {
  return (
    <div className="step-item">
      <div
        className="logo"
        style={{
          backgroundColor: color,
        }}
      >
        {index}
      </div>
      <div className="content">
        <div className="title">{`Step ${index}`}</div>
        <div className="text">{description}</div>
      </div>
    </div>
  );
};
