import "./privacy.css";

export const Privacy = ({ lenderName }) => {
  return (
    <div className="privacy-container">
      <div className="text">
        Thanks for choosing {lenderName} for your financial needs!
      </div>
      <div className="privacy">
        <a
          href="https://www.lokyata.com/assets/Privacy%20Policy%20_%20Lokyatav2.pdf"
          target="_blank"
        >
          Privacy Policy
        </a>
      </div>
    </div>
  );
};
